
import { Component, Vue, Watch } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import ApplyPeralteeForm from "@/components/consultant/forms/ApplyPeralteeForm.vue"
import PeralteesList from "@/components/consultant/PeralteesList.vue"
import {
  ClaimState,
  ConsultantPeralteeClaim,
  ConsultantPeralteeClaimsFilter,
  MyPeralteesQuery,
  MyPeralteesQueryQuery,
  MyPeralteesQueryQueryVariables,
} from "@/gql"
import { NetworkStatus } from "@apollo/client/core"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"

@Component({
  components: {
    AppPage,
    ApplyPeralteeForm,
    PeralteesList,
  },
  apollo: {
    claims: {
      query: MyPeralteesQuery,
      variables() {
        return this.queryVars
      },
      result(result) {
        if (result.networkStatus === NetworkStatus.ready) {
          this.isLoading = false
          this.errorLoading = false
        }
      },
      error(error) {
        if (error.graphQLErrors) {
          this.errorLoading = true
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      update(data) {
        return data.consultantProfile?.peralteeClaims.data || []
      },
    },
  },
})
export default class MyPeraltee extends Vue {
  showApplyPeralteeDialog = false
  peralteeClaims: {
    [key: string]: ConsultantPeralteeClaim[]
  } = {}
  claims: ConsultantPeralteeClaim[] = []
  filter: FilterType = {}

  isLoading = false

  tab = "pending"
  selectedState = ClaimState.Pending

  @Watch("filter")
  onFilterChange() {
    this.$apollo.queries.claims?.refetch()
  }

  async fetchConsultantPeraltees(filter: ConsultantPeralteeClaimsFilter) {
    this.isLoading = true

    const response = await this.$apollo.query<
      MyPeralteesQueryQuery,
      MyPeralteesQueryQueryVariables
    >({
      query: MyPeralteesQuery,
      variables: {
        filter: filter,
      },
    })

    if (response && response.data.consultantProfile) {
      this.isLoading = false
      return response.data.consultantProfile?.peralteeClaims.data
    }
  }

  get groupedPeralteeClaims() {
    return this.claims.reduce(
      (acc, claim) => {
        const key = claim.state

        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(claim)
        return acc
      },
      {
        pending: [],
        approved: [],
        rejected: [],
      } as { [key: string]: ConsultantPeralteeClaim[] }
    )
  }

  get claimsCount() {
    return this.groupedPeralteeClaims[this.tab]?.length || 0
  }

  get queryVars() {
    return {
      filter: this.filter,
    }
  }

  closeDialog() {
    this.showApplyPeralteeDialog = false
  }
}
