
import {
  CancelConsultantPeralteeClaimRequestMutation,
  CancelConsultantPeralteeClaimRequestMutationMutation,
  CancelConsultantPeralteeClaimRequestMutationMutationVariables,
  ClaimState,
  ConsultantPeralteeClaim,
  MyPeralteesQuery,
} from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"
import { useTimeAgo } from "@/hooks/useTimeAgo"

@Component
export default class PeralteesList extends Vue {
  @Prop() readonly claims!: ConsultantPeralteeClaim[]
  @Prop() readonly loading!: boolean
  @Prop() readonly state!: ClaimState

  selectedClaim: ConsultantPeralteeClaim | null = null
  isLoading = true
  peralteesPerPage = 10
  pagination = { page: 1, per: 10 }
  showPeralteeDetailDialog = false
  isCancelling = false

  get paginationLength() {
    return Math.round(this.claims.length / this.pagination.per)
  }

  peralteesQuery = {
    query: MyPeralteesQuery,
    variables: {
      filter: {},
    },
  }

  initSelectedClaim(claim: ConsultantPeralteeClaim) {
    this.showPeralteeDetailDialog = true
    this.selectedClaim = claim
  }

  async cancelClaimRequest() {
    this.isCancelling = true

    const result = await this.$apollo.mutate<
      CancelConsultantPeralteeClaimRequestMutationMutation,
      CancelConsultantPeralteeClaimRequestMutationMutationVariables
    >({
      mutation: CancelConsultantPeralteeClaimRequestMutation,
      variables: {
        id: this.selectedClaim?.id,
      },
      refetchQueries: [this.peralteesQuery],
    })

    if (result.data && !result.data.cancelConsultantPeralteeClaimRequest.error) {
      this.addSuccess("Claim cancelled successfully!")
      this.showPeralteeDetailDialog = false
    }

    this.isCancelling = false
  }

  timeAgo = (date: string) => {
    return useTimeAgo(date)
  }
}
